export const getStyle = (size, textType, colorFont) => {
  let style = {};
  const [screenWidth, screenHeight] = size.split('x').map(Number); // Extrai a largura e a altura da resolução

  const fontSizeFactor = screenWidth / 1920; // Um fator de escala para o tamanho do texto
  const titleTopPosition = screenHeight * 0.05; // Posição para o título (5% do topo)
  const priceTopPosition = screenHeight * 0.9;  // Posição para o preço (90% do topo)

  if (textType === 'title') {
    style = {
      left: 0,
      top: titleTopPosition,
      fontFamily: "Arial",
      fontSize: 85 * fontSizeFactor, // Escala o tamanho da fonte
      textBackgroundColor: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      fontWeight: "bold",
      fill: colorFont,
      antiAlias: true,
      width: screenWidth, // A largura é sempre proporcional à largura da tela
      textAlign: "center",
    };
  } else if (textType === 'price') {
    style = {
      left: 0,
      top: priceTopPosition, // Preço posicionado na parte inferior
      fontFamily: "Arial",
      fontSize: 100 * fontSizeFactor, // Escala o tamanho da fonte
      textBackgroundColor: "transparent",
      stroke: "transparent",
      strokeWidth: 0,
      fontWeight: "bold",
      fill: colorFont,
      antiAlias: true,
      width: screenWidth,
      textAlign: "center",
    };
  }

  return style;
};


export function getImagePosition(size) {

  const newSize = getScreenSize(size)
  const [screenWidth, screenHeight] = newSize.split('x').map(Number); // Extrai a largura e a altura da resolução

  // Proporções baseadas em uma resolução de referência, como 1920x1080
  const leftPosition = screenWidth * 0.32; // Centraliza horizontalmente (50% da largura)
  const topPosition = screenHeight * 0.2; // Centraliza verticalmente (50% da altura)

  return {
    left: leftPosition,  // Proporção da posição à esquerda
    top: topPosition,    // Proporção da posição superior
  };
}


function getScreenSize({ x, y }) {
  const roundedX = Math.round(x);
  const roundedY = Math.round(y);
  return `${roundedX}x${roundedY}`;
}
 
  